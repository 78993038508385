.home-title h1{
    display: flex;
    justify-content: center;
    margin-top: 10%;
    margin-left: 20%;
    margin-right: 20%;
    background-image: radial-gradient(circle farthest-corner at center, rgba(93, 180, 252, 1) 35%, rgba(165, 166, 246, 1) 65%, rgba(215, 151, 221, 1) 100%);
    padding: 10%;
    font-family: optima, segoe, segoe ui, candara, calibri, arial, sans-serif;
    font-weight: 100;
    box-shadow: 10px 10px 10px rgba(45,55,68,0.30);
    transition: 1s;
}

.home-title h1:hover{
    transform: scale(1.1);
    z-index: 2;
}