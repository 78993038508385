.token-reinforcement-page{
    display: flex;
}

.left{
    width: 16%;
    margin: 0;
    overflow-y: auto;
    height: 100vh;
    background-color: #f1f0ff;
}

.right{
    width: 80%;
    margin: .5%;
    border: solid black 2px;
    border-radius: 5px;
    height: 70vh;
}