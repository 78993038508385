.new-visual-form-container{
    margin-top: 50vh;
    margin-left: 25%;
    padding: 3%;
    background-image: radial-gradient(circle farthest-corner at center, rgba(93, 180, 252, 1) 35%, rgba(165, 166, 246, 1) 65%, rgba(215, 151, 221, 1) 100%);
    width: 40%;
    text-align: center;
    font-family: optima, segoe, segoe ui, candara, calibri, arial, sans-serif;
    font-size: 20px;
    font-weight: 100;
    box-shadow: 10px 10px 10px rgba(45,55,68,0.30);
}

.new-visual-form-container h4{
    font-family: optima, segoe, segoe ui, candara, calibri, arial, sans-serif;
    font-size: 20px;
    font-weight: 100;
    margin: 0;
}
