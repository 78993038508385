.visual-card{
    width: 200px;
    min-height: 150px;
    margin-top: 10px;
    background-color: #a5a6f6;
    border: solid black 2px;
    border-radius: 10px;
    font-family: optima, segoe, segoe ui, candara, calibri, arial, sans-serif;
    font-weight: 100;
    transition: 1s;
}

.visual-card:hover{
    transform: scale(1.1);
    box-shadow: 2px 2px 2px #000;
    z-index: 2;
}

img{
    min-width: 150px;
    height: 100px;
    margin: 10px 10px 2px 25px;
}

.visual-card h3{
    margin: 0px;
    text-align: center;
}

.visual-card-footer{
    display: flex;
    justify-content: space-between;
    margin: 2px;
}

/* setup of card in container:
1. changed to width, from min-width to keep cards in container the same size as cards in the collection. */