html, body {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

#root {
  height: 100vh;
}

button{
  appearance: none;
  background: none;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #212121;
  cursor: pointer;
  transition: 0.4s;
  color: #431991;
  border: 2px solid #431991;
  background-image: linear-gradient(to top, transparent 50%, #431991 50%);
  background-size: 100% 200%;
  background-position: 0% 100%;
}

button:hover{
  color: #fff;
  background-position: 0% 0%;
}

.submit{
  appearance: none;
  background: none;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #212121;
  cursor: pointer;
  transition: 0.4s;
  color: #431991;
  border: 2px solid #431991;
  background-image: linear-gradient(to top, transparent 50%, #431991 50%);
  background-size: 100% 200%;
  background-position: 0% 100%;
}

.submit:hover{
  color: #fff;
  background-position: 0% 0%;
}