.token-reinforcement-board-container{
    width: 90%;
    height: 30%;
    margin-left: 1%;
    margin-right: 1%;
    padding: .5%;
}
.token-reinforcement-board-container-one{
    display: flex;
}

.token-reinforcement-board{
    font-family: optima, segoe, segoe ui, candara, calibri, arial, sans-serif;
    font-size: 50px;
    font-weight: 100;
    margin-right: 30%;
}

.token-reinforcement-board-square{
    border: solid black 2px;
    width: 200px;
    min-height: 150px;
    margin-top: 10px;
    border-radius: 10px;
}

input[type="radio"]{
    display: none;
}

.stars{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 70vh;
    
}

.star{
    cursor: pointer;
    transition: color 200ms;
}