.login-form{
    margin-top: 15%;
    margin-left: 35%;
    margin-right: 35%;
    padding: 1%;
    background-image: radial-gradient(circle farthest-corner at center, rgba(93, 180, 252, 1) 35%, rgba(165, 166, 246, 1) 65%, rgba(215, 151, 221, 1) 100%);
    font-family: optima, segoe, segoe ui, candara, calibri, arial, sans-serif;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    box-shadow: 10px 10px 10px rgba(45,55,68,0.30);
    transition: 1s;
}

.login-form:hover{
    transform: scale(1.1);
    z-index: 2;
}