.nav-bar{
    background-image: radial-gradient(circle farthest-corner at center, rgba(93, 180, 252, 1) 35%, rgba(165, 166, 246, 1) 65%, rgba(215, 151, 221, 1) 100%);
    padding: 2%;
    box-shadow: 10px 10px 10px rgba(45,55,68,0.30);
}

.nav{
    font-size: 25px;
    font-family: optima, segoe, segoe ui, candara, calibri, arial, sans-serif;
    display: flex;
    justify-content: space-around;
}
