.first-then-board-container{
    width: 95%;
    height: 30%;
    margin-left: 1%;
    margin-right: 1%;
    padding: .5%;
}

.first-then-board{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 4em;
    font-family: optima, segoe, segoe ui, candara, calibri, arial, sans-serif;
    font-size: 50px;
    font-weight: 100;
}

.first-then-board-visual-cards-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 7em;
}

/* setup of card container:
grid template column means repeat 3 columns/times, at 1 fraction of the space/page)
grid gap creates a gap between cards */

